blockquote {
    @include padding-rem(20 20 20 0);
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    align-content: flex-start;
    
    @include breakpoint($breakpoint-medium) {
        flex-wrap: wrap;
        @include padding-rem(20 0 20 0);
    }
    
    &:before {
        content: "\201C";
        color: $color-primary;
        display: inline-block;
        flex: 1 1 10px;
        font-family: "Open Sans";
        @include font-size(40);
        line-height: 1;
        position: relative;
        height: 16px;
        width: 20px;
    }
    q {
        color: $color-primary;
        @include font-size(26);
        @include padding-rem(0 10);
        
        @include breakpoint($breakpoint-medium) {
            @include font-size(20);
            flex: 0 0 90%;
        }
        
        @include breakpoint($breakpoint-small) {
            @include font-size(20);
            flex: 0 0 85%;
        }
        
        &:before,
        &:after {
            content: "";
            content: none;
        }
    }
    
    .quote-image {
        text-align: center;
        min-width: 1px;
        
        img {
            @include breakpoint($breakpoint-medium) {
                display: inline-block;
            }
        }
        
        @include breakpoint($breakpoint-medium) {
            @include font-size(20);
            flex: 0 0 100%;
            align-self: center;
        }
        
    }
    
	.quote-author {
        @include font-size($font-size-body);
        display: block;
        width: 100%;
	}    
}

