/* footer container 
--------------------- */
footer {
	background: $color-bg-footer;
    @include padding-rem(0 $font-spacing 0 $font-spacing);
	@include margin-rem(0);
    position: relative;
	width: 100%;

    .footer-content {
        align-items: center;
        display: flex;
        justify-content: space-between;
        min-height: $minheight-footer;
        width: 100%;
        
            
        @include breakpoint($breakpoint-large) {
		    flex-wrap: wrap;
	    }
        
        .copy {	
		    color: $color-copyright-footer;
		    display: inline-block;
		    @include font-size($font-size-footer);
            line-height: $font-lineheight-footer;
	    	}
    
        .footer-links-wrapper {
            display: inline-block;
            flex-wrap: wrap;
            text-align: right;

            .footer-links {
                color: $color-text-footer;
                @include font-size($font-size-footer);
                line-height: $font-lineheight-footer;

                li {	
                    border-left: none;
                    float: left;
                    list-style: none;
                    
                    a {	
                        color: $color-link-footer;
                        
                        &:hover {
                            color: $color-link-footer-hover;
                        }
                    }
                    
                    &:before {
                        color: $color-divider-footer;
                        content: "|";
                        display: inline-block;
                        margin: 0 5px;
                    }
                    
                    &:first-child:before {
                        content: "";
                        content: none;
                    }
                } 
            }
        }
    }
}

.subfooter a {
    text-decoration: none;
}