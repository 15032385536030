/* Tables
--------------------- */
table {
	border-collapse: collapse;
    caption-side: bottom;
	margin: $font-spacing 0 $font-spacing 0;
	text-align: left;
    max-width: 100%;
	
    caption {
        color: $color-tbl-caption;
        font-size: 11px;
        margin-bottom: 0.8em;
        margin-top: -0.8em;
	    }
	
	tr {
       th,td {
          border-style: solid;
          border-width: 1px;
          padding: 4px;
       }
        td {
            border-color: $color-td-border;
    		color: $color-td-txt;
		}
        th {
            background: $color-th-bg;
            font-weight: bold;
            color: $color-th-txt;
        }
    }
    h1, h2, h3, h4, h5, h6, ul, p {
	    margin: 0;
	}
}
	

