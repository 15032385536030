/* Mobile menu */
#mobilenav-wrapper {

    display: none;
    #mobilenav {
        font-family: $font-family-mobilenav;
        @include font-size($font-size-mobilenav);
        line-height: $font-lineheight-mobilenav;
        padding: 60px 0 0 0;
        width: 100%;

        li, li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after{
            float: none;
            margin: 0;
            width: 100%;
        }

        &.mm-panel > .mm-listview {
            margin: 0;

            & > li:not(.mm-divider)::after {
                left: 0;
            }
        }

        a {
            color: $color-grey20;
            outline: none;
        }
        &:hover,
        &.active {
            a {
                background: transparent;
            }
        }
    }
}

/* Mobile button */
#mobilenav-btn-wrapper {
    display: none;
        
    @include breakpoint($breakpoint-small) {
        display: block;
    }
    
    .mobilenav-btn { 
        font-family: $font-family-mobilebtn;
        @include font-size($font-size-mobilebtn);
        line-height: $font-lineheight-mobilebtn;
        display: block;
        color: $color-mobilenav-link;
        height: 30px;
        @include font-size(16);
        padding: 0;
        position: relative;
        text-decoration: none;
        z-index: 9999999;

        &:hover {
            color: $color-mobilenav-hover;    
        }
        .mobilenav-icon:before, 
        .mobilenav-icon:after,
        .mobilenav-icon span {
            background: $color-mobilenav-link;
            content: '';
            display: block;
            width: 26px;
            height: 4px;
            position: absolute;
            right: 0px;
            transition: none 0.5s ease 0.5s;
            transition-property: transform, top, bottom, right, opacity;
        }

        .mobilenav-icon:before {
            top: 5px;
        }
        .mobilenav-icon:after {
            bottom: 5px;
        }
        .mobilenav-icon span {
            top: 50%;
            margin-top: -2px;
        }

        .mobilenav-txt {
            display: block;
            padding: 4px 38px 4px 0px;
            text-transform: uppercase;
        }

        &.mobilenav-opened {

            .mobilenav-icon {
                span {
                    right: -50px;
                    opacity: 0;
                }
                &:before {
                    transform: rotate( 45deg );
                    top: 13px;
                }
                &:after {
                    transform: rotate( -45deg );
                    bottom: 13px;
                }
            }

        }
    }
}


        
	

