/* Content container 
--------------------- */
main {
	max-width: 100%;
	margin: 0 auto;
	width: 100%;
}


/* Blocks  
--------------------- */
//.intro,
.background-slider__slide,
.content-main,
.content-process,
.quotes,
.color-blocks,
.creators,
.subfooter,
.block-content-image,
.block-content,
.info,
.solutions,
.projects,
.creators-default,
.vacatures,
.partners,
.project-detail,
.contact-block {
	@extend %block;
	@include padding-rem(0 0 40 0);
	max-width: 100%;

	.content {
		display: block;
		margin: 0 auto;
		max-width: $breakpoint-xxlarge;
		overflow: auto;
	}

	@include breakpoint($breakpoint-xxlarge) {
		@include padding-rem(0 20 40 20);
	}
}

.color-blocks ul,
.columns,
.columns.content,
.creators ul,
.subfooter .content,
.creator-wrapper {
	display: flex;

	@include breakpoint($breakpoint-large) {
		flex-wrap: wrap;
	}

	> * {
		min-width: 1px;

		@include breakpoint($breakpoint-medium) {
			flex: 0 0 100% !important;
		}
	}
}

/* Intro */
.intro {
	//background-image: url("../gfx/tmpl/slider-1.jpg");
	position: relative;
	min-height: 200px;

	//&:after {
	//	content: "";
	//	background-image: url("../gfx/tmpl/img-overlay.png");
	//	background-repeat: no-repeat;
	//	background-size: cover;
	//	display: block;
	//	position: absolute;
	//	right: 0;
	//	z-index: 1;
	//	height: 100%;
	//	width: 100%;
	//	top: 0;
	//}
	//
	.content {
		position: relative;
		z-index: 2;

		.btn {
			margin-top: 50px;
		}
	}

	h1 {
		color: $color-white;
	}
	ul {
		margin-top: 50px;
		* {
			color: darken($color-white, 15%);
		}

		li {
			font-size: 1.3em;
			&:before {
				top: 0 !important;
			}
		}
	}
}

.intro-overlay {
	content: "";
	background-image: url("../gfx/tmpl/img-overlay.png");
	background-repeat: no-repeat;
	background-size: cover;
	display: block;
	position: absolute;
	right: 0;
	z-index: 1;
	height: 100%;
	width: 100%;
	top: 0;
}

.content-main {

	.columns {

		> div {
			flex: 0 0 50%;

			&:first-child {
				@include padding-rem(0 $font-spacing 0 0);
			}

			&.main-image {
				background-image: url("../gfx/tmpl/slider-3.jpg");
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
				border-radius: 50%;
				overflow: hidden;
				height: auto;
				width: 100%;
				max-width: 365px;
				flex: 0 0 30%;
				align-self: start;

				@include breakpoint($breakpoint-medium) {
					@include margin-rem(40 0 0 0);
				}

				&:before {
					content: "";
					display: block;
					padding-top: 100%; 	/* initial ratio of 1:1*/
				}
			}

			&:last-child {
				flex: 0 0 20%;
				max-width: 210px;

				@include breakpoint($breakpoint-medium) {
					@include margin-rem(40 0 0 0);
				}

				@include breakpoint($breakpoint-small) {
					display: none;
				}

				img {
					width: 100%;
				}
			}
		}
	}
}

.quotes {
	//	@include padding-rem(0 0 10 0);

	q {
		font-size: 1.7rem;
	}

	.homepage & {
		//background-color: $color-bg-block-secondary;
	}

	.slider-wrapper .bx-wrapper .bx-viewport {
		background: transparent;
	}
}

.color-blocks {
	background-color: $color-bg-block-primary;
}

.creators {
	@include padding-rem(0 20 10 20);

	h3 {
		color: $color-primary;
		text-align: center;
		position: relative;

		&:before {
			background: linear-gradient(to right, rgba($color-border-creators,1) 0%,rgba($color-border-creators,1) 35%,rgba($color-border-creators,0) 35%,rgba($color-border-creators,0) 65%, rgba($color-border-creators,1) 65%,rgba($color-border-creators,1) 100%);
			content: "";
			color: $color-primary;
			display: block;
			height: 2px;
			width: 100%;
			position: absolute;
			top: 50%;

			@include breakpoint($breakpoint-medium) {
				background: none;
			}
		}
	}

	.columns > div {
		flex: 1 1 15%;

		&:first-child {
			flex: 1 1 50%;
			@include padding-rem (0 $font-spacing 0 0);
		}
		&:last-child {
			flex: 1 1 35%;
		}

		@include breakpoint($breakpoint-medium) {
			margin-left: 0;
			@include margin-rem(0 0 40 0);
		}

		ul {
			flex-wrap: wrap;

			.content & {
				margin: 0;
			}

			li {
				flex: 1 1 48%;
				margin-left: 2%;

				.content &:before {
					content: "";
					content: none;
				}

				@include breakpoint($breakpoint-medium) {
					margin-left: 0;
				}

				a {
					display: block;
					border: 1px solid $color-grey80;
					@include padding-rem(10);
					@include margin-rem(0 0 10 0);
					text-decoration: none;
				}
			}
		}

		img {
			width: 100%;
		}
	}

	.quotes {
		display: block;
		background-color: transparent;
		max-width: 60%;
		margin: 0 auto;

		@include breakpoint($breakpoint-medium) {
			max-width: 100%;
		}


		blockquote {

			&:before {
				order: 2;

				@include breakpoint($breakpoint-medium) {
					@include margin-rem(40 0 0 0);
					flex: 0 0 100% !important;
				}
			}

			q {
				@include font-size(1.7rem);
				order: 3;
				@include breakpoint($breakpoint-medium) {
					@include margin-rem(-10 0 0 0);
				}
			}

			.quote-image {
				background-image: url("../gfx/tmpl/slider-2-medium.jpg");
				background-repeat: no-repeat;
				background-position: center center;
				background-size: none;
				overflow: hidden;
				height: auto;
				border-radius: 50%;
				width: 100%;
				order: 1;
				flex: 0 0 15%;
				align-self: flex-start;
				@include margin-rem(0 10 0 0);

				@include breakpoint($breakpoint-medium) {
					flex: 0 0 100%;
					@include margin-rem(0);
					max-width: 200px;
					align-self: center;
				}

				&:before {
					content: "";
					display: block;
					padding-top: 100%; 	/* initial ratio of 1:1*/
				}
			}
		}
	}

}

.info {
	background-color: $color-tertiary;
	@include padding-rem(0 20 10 20);

	@include breakpoint($breakpoint-medium) {
		@include padding-rem(0 20 30 20);
	}

	.columns {
		align-items: center;
		align-content: center;
		justify-content: space-between;

		> div >.collapsible__trigger {
			font-size: 1.9rem;
		}
	}

	h3, p {
		color: $color-white;
	}

	.content {
		h3 {
			@include margin-rem(20 0 0 0);
			font-size: 3rem;
		}
		p {
			@include margin-rem(10 0 15 0);
			@include alpha-attribute("color", rgba($color-white, 0.7), $color-white);
		}
	}
}


.block-content-image {
	background-color: $color-bg-block-secondary;
	@include padding-rem(20 20 40 20);

	.columns {
		> div {
			flex: 0 0 49%;
			min-width: 1px; /* images scale in flexbox */

			&:first-child {
				order: 2;

				img {
					@include margin-rem($font-spacing 0 0 0);
				}
			}

			&:last-child {
				margin-right: 2%;
				order: 1;
			}
		}
	}


}

.block-content {
	.columns {
		> div {
			flex: 0 0 49%;
			max-width: 100%;

			&:last-child {
				margin-left: 2%;
				@include breakpoint($breakpoint-medium) {
					margin-left: 0;
				}
			}
		}
	}
}

.solutions {
	background-color: $color-bg-block-primary;

	.content {
		h2 {
			color: $color-white;
			@include margin-rem(40 0 40 0);
		}

		h4 {
			@include margin-rem(0);
			padding-right: 30px;
		}
	}

	ul {
		.content ul {
			margin: 0;
		}

		> li {
			@include margin-rem(0 0 10 0);
			position: relative;

			.content &:before {
				content: "";
				content: none;
			}

			a:not(.collapse):not(.expand) {
				background-color: $color-white;
				display: block;
				text-decoration: none;
				@include padding-rem(20);
			}
		}
	}
	.expand,
	.collapse {
		border-radius: 6px;
		color: $color-white;
		position: absolute;
		right: 20px;
		top: 25px;
		text-decoration: none;
		background-color: $color-tertiary;
		text-align: center;
		line-height: 24px;
		height: 24px;
		width: 24px;
		vertical-align: middle;
	}
}

.projects {
	background-color: $color-bg-block-secondary;
	@include padding-rem(40 20);

	.content {
		ul {
			margin: 0;
			li {
				&:before {
					content: "";
					content: none;
				}
			}
		}

		h3 {
			@include margin-rem(0 0 0 0);
		}
		p {
			color: $color-grey60;
			@include margin-rem(($font-spacing * 0.5) 0 $font-spacing 0)
		}
	}

	.columns {
		flex-wrap: wrap;
		justify-content: space-between;

		li {
			flex: 0 0 49%;
			@include margin-rem(0 0 20 0);
			position: relative;

			a:not(.fa) {
				background-color: $color-white;
				display: block;
				@include padding-rem(30 40);
				text-decoration: none;
			}
			.fa {
				border-radius: 6px;
				color: $color-white;
				position: absolute;
				right: 20px;
				top: 25px;
				text-decoration: none;
				background-color: $color-tertiary;
				text-align: center;
				line-height: 24px;
				height: 24px;
				width: 24px;
				vertical-align: middle;
			}
			&:nth-child(2n) {
				margin-left: 2%;

				@include breakpoint($breakpoint-medium) {
					@include margin-rem(0);
				}
			}
		}
	}
}

.creators-default {
	background-color: $color-bg-block-secondary;
	@include padding-rem(40 0);

	.slider-wrapper {

		.bx-wrapper .bx-controls {
			display: block;

			.bx-prev {
				left:20px;
			}
			.bx-next {
				right: 20px;
			}
		}
	}
	.bx-pager-wrapper {
		display: none;
	}

	.creator-wrapper {
		@include padding-rem(40 80);

		> div {
			flex: 0 0 80%;
			min-width: 1px;

			&:first-child {
				flex: 0 0 20%;
				@include padding-rem(0 20 0 0);
			}
		}
		h4 {
			margin-top: 0;
		}
	}
}

.vacatures {
	background-color: $color-bg-block-secondary;
	//	@include padding-rem(40 0);

	.content {
		ul {
			margin: 0;
			li {
				&:before {
					content: "";
					content: none;
				}
			}
		}
		h3 {
			@include margin-rem(0 0 0 0);
		}
		p {
			color: $color-grey60;
			@include margin-rem(($font-spacing * 0.5) 0 ($font-spacing * 0.5) 0)
		}
	}

	.columns {
		flex-wrap: wrap;

		li {
			flex: 0 0 49%;
			@include margin-rem(0 0 20 0);
			position: relative;

			a:not(.fa) {
				background-color: $color-white;
				display: block;
				@include padding-rem(30 40);
				text-decoration: none;
			}
			.fa {
				border-radius: 6px;
				color: $color-white;
				position: absolute;
				right: 20px;
				top: 25px;
				text-decoration: none;
				background-color: $color-tertiary;
				text-align: center;
				line-height: 24px;
				height: 24px;
				width: 24px;
				vertical-align: middle;
			}
			&:nth-child(2n) {
				margin-left: 2%;

				@include breakpoint($breakpoint-medium) {
					@include margin-rem(0);
				}
			}
		}
	}
}

.partners {
	.columns {
		justify-content: space-between;
		> div {
			flex: 0 0 30%;
		}
	}
}

.project-detail {
	background-color: $color-bg-block-secondary;

	.columns {
		div {
			flex: 0 0 33.33%;

			&:nth-child(1) {
				flex: 0 0 66.66%;
				@include padding-rem(0 40 0 0);
			}
		}
	}
}
.subfooter {
	background-color: $color-bg-block-primary;

	.content {

		* {
			color: $color-white;
		}

		> div {
			flex: 0 0 33.33%;

			&:nth-child(3) {
				ul {
					margin: 0;

					li:before {
						content: "";
						content: none;
					}
				}
			}
		}
	}
}

.content-process {
	> .columns {
		&:after {
			display: table;
			clear: both;
		}

		> div {
			float: left;
		}

	}
}


.content-process__text {
	width: 30%;
	margin-right: 3%;
	float: left;
}

.content-process__image {
	width: 66%;
}

.block-cycle {
	&:nth-child(even) {
		background: $color-bg-block-secondary;
	}
}


.content ol {
	counter-reset: numbered-list;
	> li {
		list-style: none;
		padding: 5px 0;
		&:before {
			content: counter(numbered-list);
			counter-increment: numbered-list;
			margin-right: 15px;
			background: $color-grey70;
			border-radius: 2000px;
			color: white;
			padding: 1px 7px;
		}
	}
}

.bx-slider {
	list-style: none;
	.content > & > li:before {
		content: '';
	}
}


.content ul.columns {
	> li {
		padding-left: 0;
	}
}