/* Body
--------------------- */
body {
	font-family: $font-family-body;
	@include font-size($font-size-body);
	line-height: $font-lineheight-body;
}
	
/* Paragraph
--------------------- */
p {
    color: $color-paragraph;
}

/* Hyperlinks 
--------------------- */	
a {
	color: $color-link-idle;
	text-decoration: underline;
	
	&:hover {
	    color: $color-link-hover;
		text-decoration: none;
    }
}

/* Headers
--------------------- */
h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}
	
h1 {
	color: $color-h1;
	font-family: $font-family-h1;
	@include font-size($font-size-h1);
	line-height: $font-lineheight-h1;
	font-weight: $font-weight-h1;
	}
	
h2 {
	color: $color-h2;
	font-family: $font-family-h2;
    @include font-size($font-size-h2);
	font-weight: $font-weight-h2;
	line-height: $font-lineheight-h2;
}
	
h3 {
	color: $color-h3;
	font-family: $font-family-h3;
    @include font-size($font-size-h3);
	font-weight: $font-weight-h3;
	line-height: $font-lineheight-h3;
	}

h4 {
	color: $color-h4;
	font-family: $font-family-h4;
	@include font-size($font-size-h4);
	font-weight: $font-weight-h4;
	line-height: $font-lineheight-h4;
	}

h5 {
	color: $color-h5;
	font-family: $font-family-h5;
	@include font-size($font-size-h5);
	font-weight: $font-weight-h5;
	line-height: $font-lineheight-h5;
	}

h6 {
	color: $color-h6;
	font-family: $font-family-h6;
	@include font-size($font-size-h6);
	font-weight: $font-weight-h6;
	line-height: $font-lineheight-h6;
	}