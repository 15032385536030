.slider-wrapper {
    max-width: 100%;
    width: 100%;  
    position: relative;
    
    .bx-wrapper {
        margin: 0 auto 0px;
        
        .bx-viewport {
            left: 0;
            border: 0;
            box-shadow: none; 
//            height: $slider-height;
            height: auto;
            overflow: hidden;

            .bx-slider {
                margin: 0;
                
                .content & {
                    margin: 0;
                }
                
                > li {

                    //height: $slider-height;
                    max-height: $slider-maxheight;
                    height: auto;
                    padding: 0;
                    
                    &:before {
                        content: "";
                        content: none;
                        
                        .content & {
                            content: "";
                            content: none;
                        }
                    }
                    img {
                        max-height: none;
                    }
                }
            }
        }

        .bx-caption {
        //        background-image: url("../gfx/css/caption-background.jpg");
        //        background-position: bottom center;
        //        border-bottom-style: solid;
        //        borderbottom-width: 5px;
        //        @include alpha-attribute("border-color", rgba($grey-20, 0.8), $grey-20);
            @include alpha-attribute("background-color", rgba($color-caption, 0.9), $color-caption);
        //        background-color: $grey-20;
            bottom: 20%;
            color: $color-caption-txt;
            @include font-size(20);
            font-weight: 900;
            left: auto;
            right: 0px;
            padding: 14px;
            position: absolute;
            z-index: 100;
            text-transform: uppercase;
            width: auto;   

            @include breakpoint(medium) {
                display: none;
                top: 0;
                bottom: auto;
                @include font-size(14);
                padding: 6px;
            }
            @include breakpoint(small) {
                display: none;
            }

            .caption-wrapper {
                display: block;
                margin: 0 auto;
                padding: 0 10px;
                width: 100%;
            }
        }
        
        /* images */
        .bx-loading {
            background-image: url("../gfx/vendor/bxslider/bx_loader.gif"); 
            background-repeat: no-repeat;
            background-color: #fff;
            background-position: center center;
        }
        .bx-controls {
            display: none;
        }
        
        .bx-prev, .bx-next, .bx-controls-auto .bx-start, .bx-controls-auto .bx-stop {
            background-image: url("../gfx/vendor/bxslider/controls.png");
            background-repeat: no-repeat;
        }

        .bx-prev {
            background-position: 0 -32px;
        }
        .bx-next {
            background-position: -43px -32px;
        }
        .bx-controls-auto .bx-start {
            background-position: -86px -11px;
        }
        .bx-controls-auto .bx-stop {
            background-position: -86px -44px;
        }
    }
}
        
.bx-pager-wrapper {
    height: 30px;
    position: relative;
    z-index: 99999999999;
    width: 100%;
    text-align: center;

    @include breakpoint(medium) {
        bottom: 20px;
        }

    @include breakpoint(large) {
         height: 30px;    
     }

    .bx-pager {
        display: inline-block;
        margin: 0 auto;
        padding: 0px 0 0 0;
        width: auto;

        @include breakpoint(large) {
             padding: 4px 0 0 0;
         }

        li {
            float: left;
            margin-left: 6px;
            padding: 0;

            &:before {
                content: "";
                content: none;
            }
            & a {
                height: 16px;
                color: $color-pager;
                display: inline-block;
                border-radius: 50%;
                border-style: solid;
                border-width: 1px;
                border-color: $color-pager-border;
                width: 16px;
                
                &.active {
                    background-color: $color-pager-hover;
                }
            }
        }
    }
}