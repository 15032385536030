/* font size 16 = 1.6rem = 16px */

/* font import */
$font-oswald : "Oswald", sans-serif;
//@import url(https://fonts.googleapis.com/css?family=Oswald:400,300,700);

$font-opensans : "Open Sans", sans-serif;
//@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic);

/* header */
$height-header: 120px;

/* body */
$font-size-body: 16;
$font-lineheight-body: 1.5; 
$font-family-body: $font-opensans;
$font-spacing: 20;

/* footer */
$minheight-footer: 50px;
$font-size-footer: 12;
$font-lineheight-footer: $font-lineheight-body; 

/* mainnav */
$font-size-mainnav: 20;
$font-lineheight-mainnav: $font-lineheight-body;
$font-family-mainnav: $font-family-body;

/* mobilenav */
$font-size-mobilenav: 16;
$font-lineheight-mobilenav: $font-lineheight-body;
$font-family-mobilenav: $font-family-body;

/* mobile button */
$font-size-mobilebtn: 16;
$font-lineheight-mobilebtn: $font-lineheight-body;
$font-family-mobilebtn: $font-family-body;

/* forms */
$font-size-label: $font-size-body;

/* h1 h2 h3 h4 h5 h6 */
$font-size-h1: 40;
$font-size-h2: 24;
$font-size-h3: 22;
$font-size-h4: 20;
$font-size-h5: 18;
$font-size-h6: 16;
$font-lineheight-h1: $font-lineheight-body;
$font-lineheight-h2: $font-lineheight-body;
$font-lineheight-h3: $font-lineheight-body;
$font-lineheight-h4: $font-lineheight-body;
$font-lineheight-h5: $font-lineheight-body;
$font-lineheight-h6: $font-lineheight-body;
$font-family-h1: $font-family-body;
$font-family-h2: $font-family-body;
$font-family-h3: $font-family-body;
$font-family-h4: $font-family-body;
$font-family-h5: $font-family-body;
$font-family-h6: $font-family-body;
$font-weight-h1: 700;
$font-weight-h2: 600;
$font-weight-h3: 400;
$font-weight-h4: 400;
$font-weight-h5: 500;
$font-weight-h6: 500;

/* slider */
$slider-height: auto;
$slider-maxheight: none;

/* news */
$font-size-newsoverview-date: $font-size-body * 0.9;

/* breakpoints */
$breakpoint-xsmall: 480px;
$breakpoint-small: 640px;
$breakpoint-medium: 800px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1200px;
$breakpoint-xxlarge: 1300px;
$breakpoint-wide: 1920px;