/* corporate identity */
$color-primary: #346381;
$color-secondary: #1271b7;
$color-tertiary: #15989a;
$color-quarternary: #2daa67;
$color-quinary: #7ab828;
$color-senary: #f3671b;

/* grey scales */
$color-black : #000000;
$color-grey10 : #1a1a1a; 
$color-grey20 : #333333;
$color-grey30 : #4d4d4d; 
$color-grey40 : #666666; 
$color-grey50 : #808080; 
$color-grey60 : #999999; 
$color-grey70 : #b3b3b3; 
$color-grey80 : #cccccc; 
$color-grey90 : #e5e5e5; 
$color-white : #ffffff;

/* social - brandcolors.net */
$color-facebook: #3b5998;
$color-twitter: #00aced;
$color-linkedin: #007bb6;
$color-youtube: #bb0000;
$color-flickr: #00aced;
$color-instagram: #3f729b;

/* files */
$color-pdf: #ea4c3a;
$color-word: #2a5696;

/* forms */
$color-error: #ea4c3a;
$color-success: #9ebf6a;
$color-focus: #56B4EF;
$color-label: $color-grey40;
$color-required: $color-grey40;
$color-input: $color-white;
$color-input-border: $color-grey80;
$color-focus-txt: $color-grey20;

/* blocks background */
$color-bg-block-primary: #3a424a;
$color-bg-block-secondary: #f2f2f2;

/* links default */
$color-link-idle: $color-primary;
$color-link-hover: lighten($color-link-idle,10%);

/* paragraph default */
$color-paragraph: $color-grey20;

/* header */
$color-bg-header: $color-white;

/* h1 h2 h3 h4 h5 h6 */
$color-h1: $color-primary;
$color-h2: $color-primary;
$color-h3: $color-grey10;
$color-h4: $color-grey20;
$color-h5: $color-grey30;
$color-h6: $color-grey40;

/* main navigation */
$color-link-mainnav: #3a424a;
$color-link-mainnav-hover: lighten($color-link-mainnav,10%);
$color-link-mainnav-active: $color-tertiary;

/* mobile navigation */
$color-mobilenav-link: $color-primary;
$color-mobilenav-hover: lighten($color-primary,10%);
$color-mobilenav-icon: $color-primary;

/* footer */
$color-bg-footer: $color-bg-block-primary;
$color-text-footer: $color-white;
$color-copyright-footer: $color-text-footer;
$color-link-footer: $color-white;
$color-link-footer-hover: lighten($color-link-footer,10%);
$color-divider-footer: $color-grey90;

/* search */ 
$color-search: $color-white;
$color-search-border: $color-grey80;
$color-search-focus: $color-grey80;
$color-search-focus-txt: $color-grey20;
$color-search-btn: $color-grey20;

/* button */
$color-btn-default: $color-secondary;
$color-btn-default-icon: darken($color-btn-default, 10%);
$color-btn-blue: $color-secondary;
$color-btn-blue-icon: #075b9a;
$color-btn-orange: $color-senary;
$color-btn-orange-icon: #d05810;
$color-btn-white: $color-white;
$color-btn-white-icon: $color-tertiary;

/* slider */
$color-caption: $color-grey20;
$color-caption-txt: $color-white;
$color-pager: $color-white;
$color-pager-hover: $color-secondary;
$color-pager-border: $color-secondary;

/* table */
$color-th-bg: $color-grey30; 
$color-th-txt: $color-white; 
$color-th-border: $color-white;
$color-td-bg: $color-white;
$color-td-txt: $color-grey30;
$color-td-border: $color-grey80;
$color-tbl-caption: $color-grey60;

/* blockquote */
$color-blockquote-border: $color-grey60;

/* list */
$color-list: $color-grey20;

/* news overview */
$color-newsoverview-date: lighten($color-paragraph, 30%);

/* creators */
$color-border-creators: $color-primary; 