/* Semi transparent color + fallback
------------------------------------ */
@mixin alpha-attribute($attribute, $color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  #{$attribute}: $solid-color;
  #{$attribute}: $color;
}

/* Font Size + px fallback
------------------------------------ */
@mixin font-size($sizeValue: 12){
  font-size: $sizeValue + px; //fallback for old browsers
  font-size: (0.1 * $sizeValue) + rem;
}

/* Line Height + px fallback
------------------------------------ */
@mixin line-height($sizeValue: 12){
  line-height: $sizeValue + px; //fallback for old browsers
  line-height: (0.1 * $sizeValue) + rem;
}

/* Margin rem + px fallback
------------------------------------ */
@mixin margin-rem($margin) {
    @if length($margin) == 1 {
        margin: $margin+px;
        margin: $margin/10+rem;
    }
    @if length($margin) == 2 {
        margin: nth($margin, 1)+px nth($margin, 2)+px;
        margin: nth($margin, 1)*0.1+rem nth($margin, 2)*0.1+rem;
    }
    @if length($margin) == 3 {
        margin: nth($margin, 1)+px nth($margin, 2)+px nth($margin, 3)+px;
        margin: nth($margin, 1)*0.1+rem nth($margin, 2)*0.1+rem nth($margin, 3)*0.1+rem;
    }
    @if length($margin) == 4 {
        margin: nth($margin, 1)+px nth($margin, 2)+px nth($margin, 3)+px nth($margin, 4)+px;
        margin: nth($margin, 1)*0.1+rem nth($margin, 2)*0.1+rem nth($margin, 3)*0.1+rem nth($margin, 4)*0.1+rem;
    }
}

/* Padding rem + px fallback
------------------------------------ */
@mixin padding-rem($padding) {
    @if length($padding) == 1 {
        padding: $padding+px;
        padding: $padding/10+rem;
    }
    @if length($padding) == 2 {
        padding: nth($padding, 1)+px nth($padding, 2)+px;
        padding: nth($padding, 1)*0.1+rem nth($padding, 2)*0.1+rem;
    }
    @if length($padding) == 3 {
        padding: nth($padding, 1)+px nth($padding, 2)+px nth($padding, 3)+px;
        padding: nth($padding, 1)*0.1+rem nth($padding, 2)*0.1+rem nth($padding, 3)*0.1+rem;
    }
    @if length($padding) == 4 {
        padding: nth($padding, 1)+px nth($padding, 2)+px nth($padding, 3)+px nth($padding, 4)+px;
        padding: nth($padding, 1)*0.1+rem nth($padding, 2)*0.1+rem nth($padding, 3)*0.1+rem nth($padding, 4)*0.1+rem;
    }
}

/* Breakpoints
------------------------------------ */
@mixin breakpoint($point) {
    @media (max-width: $point) { @content; }  
}