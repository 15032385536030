.background-slider {
  width: 100%;
}

.background-slider__slide {
  display: none;
  min-height: 302px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 50%;


  &.is-active {
    display: block;
  }
}