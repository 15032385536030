.btn, .btn[type="submit"], button, input[type="submit"] {
    background-color: $color-btn-default;
    border-radius: 6px;
    color: $color-white;
    display: inline-block;
    @include padding-rem(6 10 6 12);
    text-decoration: none;
    
    .wysiwyg-content &,
    .content & {
        @include margin-rem($font-spacing 0 0 0);
    }
    
    &.blue {
        background-color: $color-btn-blue;
        .fa {
            background-color: $color-btn-blue-icon; 
        }
    }
    &.orange {
        background-color: $color-btn-orange;
        .fa {
            background-color: $color-btn-orange-icon;   
        }
    }
    
    &.white {
        background-color: $color-btn-white;
        color: $color-grey30;
        
        .fa {
            background-color: $color-btn-white-icon;   
            color: $color-white;
        }
    }
    
    &:hover {
        background-color: darken($color-btn-default, 10%);
        color: $color-white;
        
        &.blue {
            background-color: darken($color-btn-blue, 10%);
        }
        &.orange {
            background-color: darken($color-btn-orange, 10%);
        }
        &.white {
            background-color: darken($color-btn-white, 10%);
            color: $color-grey30;
        }
    }
    
    .fa {
        background-color: $color-btn-default-icon;
        border-radius: 6px; 
        margin-left: 5px;
        padding: 5px 8px;
        
        &.fa-angle-right {
            padding: 5px 10px;
        }
    }
    
}

input[type="submit"] {
    @extend .orange;
    font-size: inherit;
    font-weight: normal;
}