
.news-item__title {
	> a {
		text-decoration: none;
	}
}

.news-item__image {
	float: left;
	width: 20%;
}

.news-item__content {
	@extend %clearfix;
}

.news-item__summary {

	float: right;
	width: 78%;
}

.news-item__date {
	margin-bottom: 0.8em;
	font-size: 0.9em;
}