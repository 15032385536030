%clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }

  // IE 6/7
  *zoom: 1;
}