/* main navigation
--------------------- */
#mainnav-wrapper {
    @include breakpoint($breakpoint-small) {
        display: none;
    }
    
    #mainnav {
        font-family: $font-family-mainnav;
        @include font-size($font-size-mainnav);
        line-height: $font-lineheight-mainnav;
        
        > li {
            float: left;
            @include margin-rem(0 0 0 $font-spacing);

            > a {
                color: $color-link-mainnav;
                display: block;
                text-decoration: none;
            }         
            &:hover > a {
                color: $color-link-mainnav-hover;
            }
            &.active > a {
                color: $color-link-mainnav-active;
            }
        }
    }	
}