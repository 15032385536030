.color-divider {
    width: 100%;
    
    > ul {
        display: flex;
        
        .content & {
            margin: 0;
        }
    
        li {
            height: 8px;
            width: 100%;
            
            .content &:before {
                content: "";
                content: none;
            }
            
            &:nth-child(1) {
                background-color: $color-bg-block-primary;
            }
            
            &:nth-child(2) {
                background-color: $color-primary;
            }

            &:nth-child(3) {
                background-color: $color-secondary;
            }

            &:nth-child(4) {
                background-color: $color-tertiary;
            }

            &:nth-child(5) {
                background-color: $color-quarternary;
            }

            &:nth-child(6) {
                background-color: $color-quinary;
            }
        }
    }
    
//    background: linear-gradient(to right, 
//        rgba($color-primary,0) 0%,rgba($color-primary,0) 16.67%,
//        rgba($color-primary,1) 16.67%,rgba($color-primary,1) 33.33%, 
//        rgba($color-secondary,1) 33.33%,rgba($color-secondary,1) 50%,
//        rgba($color-tertiary,1) 50%,rgba($color-tertiary,1) 67.67%,
//        rgba($color-quarternary,1) 67.67%,rgba($color-quarternary,1) 83.33%,
//        rgba($color-quinary,1) 83.33%,rgba($color-quinary,1) 100%);
//    

}