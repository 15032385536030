.wysiwyg-content,
.content {
    display: inline-block;
    width: 100%;
    
    /* Paragraph */
    p {    
        @include margin-rem($font-spacing 0 $font-spacing 0);
        
        a {
            color: $color-senary;
        }
    }
    
    /* Headers */
    h1, h2, h3, h4, h5, h6 {
        @include margin-rem(($font-spacing * 2) 0 10 0);
    }
    
    /* Ordered list */
	ol { 
		list-style: decimal;
        @include margin-rem($font-spacing 0 $font-spacing 0);
		
		li {
			display: list-item;
            background: none;
			margin-left: 20px;
			padding-left: 0;
			}
    }
    /* Unordered list */ 
    ul { 
        @include margin-rem($font-spacing 0 $font-spacing 0);
        
        li { 
 		    position: relative;
            padding-left: 30px;
	    
            &:before {
                position: absolute;
                left: 0;
                top: -5px;
                content: "\f05d";
                display: inline-block;
                font-family: FontAwesome;
                font-size: 2.2rem !important;
                //@include font-size(7);
                font-style: normal;
                font-weight: normal;
                padding-right: 10px;
                vertical-align: middle;
                -webkit-font-smoothing: antialiased;
  			}
            
            & ul { 
                @include margin-rem(0 0 ($font-spacing/2) 0);
                li {
                    padding-left: 15px;
                }
            }
        }
    }
    
    /* Table */ 
    table {
        @include margin-rem($font-spacing 0);
        width: auto !important;
    }
    
    /* Image */
    .cc-image {
        @include margin-rem($font-spacing 0);
        
        &.left {
            float: left;
            @include margin-rem($font-spacing $font-spacing $font-spacing 0);
        }
        &.right {
            float: right;
            @include margin-rem($font-spacing 0 $font-spacing $font-spacing);
        }
    }
    
    /* Blockquote */
    blockquote {
        @include margin-rem($font-spacing 0);
    }
}		