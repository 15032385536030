//http://jaydenseric.com/blog/forget-normalize-or-resets-lay-your-own-css-foundation

html {
    -ms-text-size-adjust: 100%;            
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 62.5%;
}

body, h1, h2, h3, h4, h5, h6, p, blockquote, figure, ol, ul {
    margin: 0;
    padding: 0;
}

div, span, h1, h2, h3, h4, h5, h6, p, a, blockquote, figure, ol, ul, li, header, main, footer, article, section, aside, input, select, textarea, fieldset, label {
    box-sizing: border-box;
}

ul, ol {
    list-style: none;
    }

li {
   display: block;
}

h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
}

a { 
    text-decoration: none;
    color: inherit;
}

img {
    max-width: 100%;
    height: auto;
    border: 0;
}