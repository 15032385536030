/* Reset elements
--------------------- */
/* Search Remove Standard Styles  */
input[type=search] { 
	-webkit-appearance: none;
}
input[type="search"]::-webkit-search-decoration, 
input[type="search"]::-webkit-search-cancel-button {
	display: none;
}

/*  Placeholders onzichtbaar maken bij focus */
input:focus::-webkit-input-placeholder,	textarea:focus::-webkit-input-placeholder { 
    color:transparent;
}
input:focus::-moz-placeholder, textarea:focus::-moz-placeholder {  /* Firefox 19+ */
    color: transparent;
}
input:focus:-moz-placeholder, textarea:focus:-moz-placeholder { /* Firefox 18- */
    color:transparent;
}
input:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder {
    color:transparent;
}

/* Validation Balloons in supported browsers  */
::-webkit-validation-bubble-message,
::-webkit-validation-bubble-message > div > div + *,
::-webkit-validation-bubble-icon,
::-webkit-validation-bubble-arrow,
::-webkit-validation-bubble-arrow:before {
	display: none;
	}

/* Chrome */
::-webkit-validation-bubble-message {
	display: none;
	}

::-webkit-validation-bubble-message:before {
	display: none;
	}

::-webkit-validation-bubble-arrow {
	display: none;
	}	

/* Content Form
--------------------- */
form {
    display: inline-block;
	float: none;
    margin: 0;
    padding: 0;
	width: 100%;
    }

legend { 
    display: none;
 }    

fieldset { 
    border: 0;
    margin: 0;
    padding: 0;
    background: none;
}

label {
    color: $color-label;
    display: inline-block;
    @include font-size($font-size-label);
    font-weight: 500;
    margin: 0 0 4px 0;
    position: relative;
    max-width: 100%;
            
    .has-error > & {
        color: $color-error;
    }
}

input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="radio"]):not([type="checkbox"]),
select, 
textarea {
    width: 100%;
    background-color: $color-input;
    border-style: solid;
    border-width: 1px;
    border-color: $color-input-border;
    border-radius: 3px;
    box-shadow: 0 0px 1px rgba(0, 0, 0, 0.15) inset, 0 0 0 transparent;
    clear: left;
    font-family: $font-family-body;
    @include font-size($font-size-body);
    float: left;
    margin: 0;
    outline: 0;
    padding: 6px 6px 6px 6px;
    
    .has-error > & {
        background-color: lighten($color-error, 40%);
    }
    
    .has-success > & {
        background-color: lighten($color-success, 35%);
    }

    &:focus {
        border-color: $color-focus;
            -Moz-box-shadow: 	0 1px 2px rgba(0, 0, 0, 0.05) inset, 0 0 2px rgba(82, 168, 236, 0.6); /* Firefox Horizontal Vertical Blur Spread Color */  
            -Webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) inset, 0 0 2px rgba(82, 168, 236, 0.6); /* Safari, Chrome */
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) inset, 0 0 2px rgba(82, 168, 236, 0.6);
        color: $color-focus-txt;
        outline: 0 none;
            -webkit-transition-duration: 0.4s;  
            -moz-transition-duration: 0.4s;  
            -o-transition-duration: 0.4s;  
        transition-duration: 0.4s;  
    }

}
    
.frm-group {
    float: left;
    margin: 0;
    position: relative;
    width: 100%;

    & ~ div {
        margin-top: 2rem;   
    }

    &.frm-required > label:after { /* Required */
        content: "*"; 
        color: $color-required;
        display: inline;
        font-family: "FontAwesome";
        @include font-size(16);
        font-style: normal;
        font-weight: bold;
        @include line-height(16);
        padding-left: 10px;
        position: absolute;
        right: 0px;
        top: 2px;
        -webkit-font-smoothing: antialiased;
        }

    label {
        float: left;
        padding-right: 10px;
        padding-top: 3px;
    }
    ul { 
        border: none;
        display: inline-block;
        width: 100%;
        padding: 0;
        
        li {
            display: flex;
            flex-wrap: wrap;            
            
            label {
                border: none;
                font-weight: 400;
                margin: 0;
                padding: 0 0 0 6px;
                flex: 0 1 auto;
            }
            input {
                margin: 6px 0 0 0;
                overflow: hidden;
                flex: 0 1 4px;
            }
        }
    }
    
    /* Textarea */
    textarea {
        height: 60px;
        resize: none;
    }
    
    /* Selectbox */
    &.frm-select label {
        display: block;
	    float: left;
	    padding-right: 10px;
    }
}

/* Submit reset
------------------------------ */
input[type="submit"], button {
    background-color: inherit;
    border: none;
    color: inherit;
    cursor: pointer;
    float: left;
    font-weight: bold;
    appearance: none;  //Remove default styling IOS
}

/* Bootstrapvalidator feedback
------------------------------ */
.help-block, .form-control-feedback {
    display: inline-block;
    float: left;
    @include line-height(16);
    max-width: 200px;
    padding: 7px 0 0 9px;
    transition: opacity 0.25s ease-in-out 0s;
    vertical-align: middle;
    
    .has-error & {
        color: $color-error;
    }
    
    .has-success & {
        color: $color-success;
    }
}
.form-control-feedback {
    float: left;
    margin-left: -32px;
    padding: 9px 0 0 9px;
    width: 22px;
    
    .frm-radio &, .frm-checkbox & {
        left: 100%;
        position: absolute;
        top: 0;
    }
}