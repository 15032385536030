/* header container 
--------------------- */
header {
	background-color: $color-bg-header;
	height: auto; 
	@include padding-rem($font-spacing $font-spacing);
    position: relative;
    width: 100%;
	
	.header-content {
		align-items: center;
		display: flex;
		justify-content: space-between;
        max-width: $breakpoint-xxlarge;
        margin: 0 auto;
		height: $height-header;
        width: 100%;
        
        /* logo */
        #logo {
            display: inline-block;
            flex: 0 0 262px;
            
            @include breakpoint($breakpoint-medium) {
                flex: 0 0 160px;
            }
            
            a {
                color: $color-primary;
                display: block;
                max-height: 100%;
                width: 100%;
                
                img {
                    display: inline-block;
                    width: 100%;
                }
                
                .logo-txt {
                    display: inline-block;
                    width: 100%;
                    
                    @include breakpoint($breakpoint-medium) {
                        left: 0px;
                        bottom: 20px;
                        position: absolute;
                        text-align: center;
                    }
                }
            }
        }
	}
}