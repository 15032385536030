#breadcrumb-wrapper {
    display: inline-block;
    padding: 0 0 0 1%;
    width: 100%;

    .breadcrumb-list {
        color: #999;
        display: inline-block;
        list-style: none;
        margin: 0;
        text-transform: lowercase;

        li {
          background: none;
          float: left;
          margin: 0px;
          padding: 0 20px 0 0;
          position: relative;

          &:before,
          &:last-child:after {
            content: "";
            content: none;
          }

          &:after {
            content: "/";
            position: absolute;
            right: 6px;
            top: 0px;
          }

          & a {
            color: #666;
            font-weight: normal;
          }
        }
    }
}