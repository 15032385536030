/* Search form
--------------------- */
.search {
  	border-color: $color-search-border;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
		-moz-border-radius: 	3px; /* Firefox */
		-webkit-border-radius: 	3px; /* Safari, Chrome */
		-ms-border-radius:		3px; /* IE */
		-o-border-radius:		3px; /* Opera */ 
	padding: 0;
	position: absolute;
	right: 0px;
	top: 55px;
	width: 208px;
    
    legend,
    label {
	    display: none;
        }
    fieldset {
	    background: none;
	    border: none;
	    padding: 0;
	}
    .searchbox {
	    background-color: $color-search;
	    border: none;   
      	margin: 0;
        outline: 0;
        padding: 6px;
	    width: 85%;
        
        &:focus {
            border-color: $color-search-focus;
		    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) inset, 0 0 8px rgba(82, 168, 236, 0.6);
			-Moz-box-shadow: 	0 1px 3px rgba(0, 0, 0, 0.05) inset, 0 0 8px rgba(82, 168, 236, 0.6); /* Firefox Horizontal Vertical Blur Spread Color */  
   			-Webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) inset, 0 0 8px rgba(82, 168, 236, 0.6); /* Safari, Chrome */
		    color: $color-search-focus-txt;
		    outline: 0 none;
            transition-duration: 0.4s;  
                -webkit-transition-duration: 0.4s;  
                -moz-transition-duration: 0.4s;  
                -o-transition-duration: 0.4s;  
            outline: 0 none;    
        }
	}
    
    .btn.search {
        background: none;
        border: none;
        color: $color-search-btn;
        cursor: pointer;
        height: 28px;
        position: absolute;
        right: 0;
        top: 0;
        width: 15%;
    }
    .btn.search span {
        @include font-size(14);
        }
}
	
/* Search results
--------------------- */
.search-wrap {
	border: 1px solid #ccc;
	float: left;	
	margin-bottom: 0.8em;
	padding: 5px;
	width: 95%;
	
	&:hover {
		background: #eee;
		}

    .search-title-wrap {
	    width: 100%;
        
        a {
            display: block;
            float: left;
            font-weight: bold;
            width: 80%;
		}
		.search-image {
			float: left;
			height: 16px;
			padding-right: 4px;
			width: 16px;
			
		    img {
				border: none;
				}
        }
        .search-title {
		    cursor: pointer;
			}
	    }
    .search-content {
    	float: left;
	    overflow: auto;	
	    width: 100%;
	}

    .search-index {
        color: #000;
        display: block;
        height: 20px;
        float: right;
        width: 40px;
	}

}
	

/* Search Pagebrowser
--------------------- */
.search-pagebrowser {
	background: none;
	color: #333;
	float: left;
	margin-right: 5px;
	padding: 0;
	
	li {
		background: none;
		float: left;
		margin-right: 1px;
		overflow: auto;
		padding: 0;
		padding-bottom: 5px;
        
        a {
			background: #666;
			border: 1px solid #666;
			color: #fff;
			display: block;
			line-height: 1.2em;
			padding: 2px 5px;
			text-decoration: none;
            
            :hover {
			    background: #fff;
			    border: 1px solid #666;
			    color: #666;
			}
        }
        
        &.active a {
    		background: #666;
    		border: 1px solid #666;
    		color:#fff;
    		}
        
		}	
}